import * as Yup from "yup";

import { MESSAGES } from "../constants";
import { Axios } from "../lib";

const getBedCapacity = async (params) => {
  try {
    const { status, data } = await Axios.get(
      `v1/property/beds/category/${params}`
    );
    return data?.personCapacity;
  } catch (error) {}
};

const wordCount = (str) => {
  return str ? str.trim().length : 0; // Split by spaces and count words
  // return str ? str.trim().split(/\s+/).length : 0; // Split by spaces and count words
};

export const propertyStepForm = (step) => {
  if (step === 1) {
    return Yup.object().shape({
      name: Yup.string()
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .test(
          "wordCount",
          "Name must be 50 words or less",
          (value) => wordCount(value || "") <= 50
        ),
      unitNumber: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]+$/,
          "Unit Number contains only digit & alphabets"
        )
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED),

      price: Yup.string()
        .matches(/^[0-9\s]+$/, "Price contains only digit")
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED),

      areaInSqft: Yup.string()
        .matches(/^[0-9\s]+$/, "Area (In Sqft ) contains only digit")
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .min(1, "Area must be greater than or equal to 1"),
    });
  }
  if (step === 2) {
    return Yup.object().shape({
      totalBeds: Yup.number(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .required()
        .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),

      totalBathrooms: Yup.number(MESSAGES.VALIDATION_ERRORS.REQUIRED).required(
        MESSAGES.VALIDATION_ERRORS.REQUIRED
      ),

      beds: Yup.array()
        .of(
          Yup.object().shape({
            bedRoomName: Yup.string(),
            items: Yup.array().of(
              Yup.object().shape({
                bedType: Yup.string().required(
                  MESSAGES.VALIDATION_ERRORS.REQUIRED
                ),
                totalBeds: Yup.number()
                  .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
                  .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
              })
            ),
          })
        )
        .required(),

      bathrooms: Yup.array().of(
        Yup.object().shape({
          private: Yup.mixed()
            .nullable("Required")
            .test("private-bathroom", function (value) {
              if (!value)
                return this.createError({
                  message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
                  path: this.path,
                });
              return { path: this.path };
            }),
          ensuite: Yup.mixed()
            .nullable("Required")
            .test("ensuite-bathroom", function (value) {
              if (this.parent.private === "true") {
                if (!value)
                  return this.createError({
                    message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
                    path: this.path,
                  });
              }
              return { path: this.path };
            }),
          bedroom: Yup.string().test("attached-bathroom", function (value) {
            if (this.parent.ensuite === "true") {
              if (!value)
                return this.createError({
                  message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
                  path: this.path,
                });
            }
            return { path: this.path };
          }),
          insideBathroom: Yup.mixed()
            .nullable("Required")
            .test("inside-bathroom", function (value) {
              if (
                this.parent.ensuite === "false" &&
                this.parent.private === "true"
              ) {
                if (!value)
                  return this.createError({
                    message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
                    path: this.path,
                  });
              }
              return { path: this.path };
            }),
        })
      ),

      personCapacity: Yup.string()
        .matches(/^[0-9\s]+$/, "Person Capacity contains only digit")
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_PERSON_CAPACTIY)
        .test("capacity-limit", async function () {
          const getGersonCapacity = await Promise.all(
            this.parent.beds?.map(async (bedCounts) => {
              const totalBeds = await Promise.all(
                bedCounts?.items?.map(async (bedtypes) => {
                  const getPersonCapacity = await getBedCapacity(
                    bedtypes?.bedType
                  );
                  const TotalCount = bedtypes?.totalBeds * getPersonCapacity;
                  return TotalCount;
                })
              );
              const total = totalBeds.reduce(
                (acc, current) => acc + current,
                0
              );
              return total;
            })
          );

          const capacityLimit = getGersonCapacity.reduce(
            (acc, current) => acc + current,
            0
          );

          if (this.parent.personCapacity > capacityLimit) {
            return this.createError({
              message: `Capacity should not exceed ${capacityLimit}`,
              path: "personCapacity",
            });
          }
          return { path: "personCapacity" };
        }),
    });
  }

  if (step === 3) {
    return Yup.object().shape({
      city: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
      area: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
      building: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
      category: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
      subCategory: Yup.array()
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .min(1, MESSAGES.VALIDATION_ERRORS.REQUIRED),
      floorNumber: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]+$/,
          "Floor Number contains only digit & alphabets"
        )
        .optional(),
    });
  }
  if (step === 4) {
    return Yup.object().shape({
      imageCategories: Yup.array().optional(),
      description: Yup.string().optional(),
      amenities: Yup.array()
        .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
        .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_AMENITIES_REQUIRED),
    });
  }
};

const PropertySchema = Yup.object().shape({
  name: Yup.string()
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .test(
      "wordCount",
      "Name must be 50 words or less",
      (value) => wordCount(value) <= 50
    ),
  unitNumber: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, "Unit Number contains only digit & alphabets")
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  city: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  area: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  building: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  category: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  subCategory: Yup.array()
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .min(1, MESSAGES.VALIDATION_ERRORS.REQUIRED),

  images: Yup.array().optional(),
  price: Yup.string()
    .matches(/^[0-9\s]+$/, "Price contains only digit")
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
  floorNumber: Yup.string()
    .matches(/^[a-zA-Z0-9\s]+$/, "Floor Number contains only digit & alphabets")
    .optional(),
  description: Yup.string().optional(),
  personCapacity: Yup.string()
    .matches(/^[0-9\s]+$/, "Person Capacity contains only digit")
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_PERSON_CAPACTIY)
    .test("capacity-limit", async function () {
      const getGersonCapacity = await Promise.all(
        this.parent.beds?.map(async (bedCounts) => {
          const totalBeds = await Promise.all(
            bedCounts?.items?.map(async (bedtypes) => {
              const getPersonCapacity = await getBedCapacity(bedtypes?.bedType);
              const TotalCount = bedtypes?.totalBeds * getPersonCapacity;
              return TotalCount;
            })
          );
          const total = totalBeds.reduce((acc, current) => acc + current, 0);
          return total;
        })
      );

      const capacityLimit = getGersonCapacity.reduce(
        (acc, current) => acc + current,
        0
      );

      if (this.parent.personCapacity > capacityLimit) {
        return this.createError({
          message: `Capacity should not exceed ${capacityLimit}`,
          path: "personCapacity",
        });
      }
      return { path: "personCapacity" };
    }),
  totalBeds: Yup.number(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .required()
    .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
  totalBathrooms: Yup.number(MESSAGES.VALIDATION_ERRORS.REQUIRED).required(
    MESSAGES.VALIDATION_ERRORS.REQUIRED
  )
  .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
  beds: Yup.array()
    .of(
      Yup.object().shape({
        bedRoomName: Yup.string(),
        items: Yup.array().of(
          Yup.object().shape({
            bedType: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
            totalBeds: Yup.number()
              .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
              .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
          })
        ),
      })
    )
    .required(),
  bathrooms: Yup.array().of(
    Yup.object().shape({
      private: Yup.mixed()
        .nullable("Required")
        .test("private-bathroom", function (value) {
          if (!value)
            return this.createError({
              message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
              path: this.path,
            });
          return { path: this.path };
        }),
      ensuite: Yup.mixed()
        .nullable("Required")
        .test("ensuite-bathroom", function (value) {
          if (this.parent.private === "true") {
            if (!value)
              return this.createError({
                message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
                path: this.path,
              });
          }
          return { path: this.path };
        }),
      bedroom: Yup.string().test("attached-bathroom", function (value) {
        if (this.parent.ensuite === "true") {
          if (!value)
            return this.createError({
              message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
              path: this.path,
            });
        }
        return { path: this.path };
      }),
      insideBathroom: Yup.mixed()
        .nullable("Required")
        .test("inside-bathroom", function (value) {
          if (
            this.parent.ensuite === "false" &&
            this.parent.private === "true"
          ) {
            if (!value)
              return this.createError({
                message: MESSAGES.VALIDATION_ERRORS.REQUIRED,
                path: this.path,
              });
          }
          return { path: this.path };
        }),
    })
  ),
  areaInSqft: Yup.string()
    .matches(/^[0-9\s]+$/, "Area (In Sqft ) contains only digit")
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .min(1, "Area must be greater than or equal to 1"),
  amenities: Yup.array()
    .required(MESSAGES.VALIDATION_ERRORS.REQUIRED)
    .min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_AMENITIES_REQUIRED),
});

export default PropertySchema;
